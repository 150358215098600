import { Translate, useTranslate } from '~/i18n';
import { AngelLeftIcon, AngelRightIcon } from '~/svgs/common';
import Container from '../common/container';
import CitiesSlider, { Region } from './cities-slider';

type TopPlacesProps = {
   regions: Region[];
};
const TopPlaces = ({ regions }: TopPlacesProps) => {
   const { dir } = useTranslate();

   const arrows = () => (
      <div className="flex items-center">
         <button
            className="swiper-arrow ltr:mr-2 rtl:ml-2 cities-slider-prev focus:outline-none focus:ring-1 ring-primary-light primary"
            aria-label="previous">
            {dir === 'rtl' ? <AngelRightIcon /> : <AngelLeftIcon />}
         </button>
         <button
            className="swiper-arrow cities-slider-next focus:outline-none focus:ring-1 ring-primary-light primary"
            aria-label="next">
            {dir === 'rtl' ? <AngelLeftIcon /> : <AngelRightIcon />}
         </button>
      </div>
   );

   return (
      <section className="mt-5">
         <div className="lg:hidden">
            <div className="justify-between ">
               <Container>
                  <div className="mb-1 text-2xl font-bold">
                     <Translate id="common:explore top cities" />
                  </div>
                  <div className="subheading">
                     <Translate id="home:top cities subtitle" />
                  </div>
               </Container>
               <div className="mt-6">
                  <CitiesSlider homepage regions={regions} />
               </div>
            </div>
         </div>
         <Container className="hidden lg:block">
            <div className="flex justify-between">
               <div>
                  <div className="mb-1 text-2xl font-bold ">
                     <Translate id="common:explore top cities" />
                  </div>
                  <div className="subheading">
                     <Translate id="home:top cities subtitle" />
                  </div>
               </div>

               {arrows()}
            </div>
            <div className="mt-6">
               <CitiesSlider regions={regions} />
            </div>
         </Container>
      </section>
   );
};

export default TopPlaces;
