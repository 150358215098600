import { CouplesIcon, FamiliesIcon, KidsIcon, LadiesIcon, SoloIcon } from '~/svgs/home-page';
import { NextLink, Translate, useTranslate } from '~/i18n';
import React, { Fragment } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import Image from '~/components/common/image';
import { SwiperOptions } from 'swiper';
import { homepageUnforgettableExperiencesBtnEvent } from '~/utils/mixpanel-events/homepage';
import style from './styles.module.css';

type CategoryProps = {
   value: string;
   icon: React.ReactNode;
   text: React.ReactNode;
   link: string;
   image: string;
   color: string;
   highlight: string;
};

const categoriesList: CategoryProps[] = [
   {
      value: 'families',
      icon: <FamiliesIcon className=" !transform-none !scale-100 relative z-10" />,
      text: <Translate id="home:families" />,
      link: '/search?query=Experiences+for+Families',
      image: '/images/home-page/categories/families.png',
      color: '#4ADE80',
      highlight: '#F0FDF4',
   },
   {
      value: 'couples',
      icon: <CouplesIcon className=" !transform-none !scale-100 relative z-10" />,
      text: <Translate id="home:couples" />,
      link: '/search?query=Experiences+for+Couples',
      image: '/images/home-page/categories/couples.png',
      color: '#F87171',
      highlight: '#FEF2F2',
   },
   {
      value: 'kids',
      icon: <KidsIcon className=" !transform-none !scale-100 relative z-10" />,
      text: <Translate id="home:kids" />,
      link: '/search?query=Experiences+for+Kids',
      image: '/images/home-page/categories/kids.png',
      color: '#FACC15',
      highlight: '#FEFCE8',
   },
   {
      value: 'solo',
      icon: <SoloIcon className=" !transform-none !scale-100 relative z-10" />,
      text: <Translate id="home:solo" />,
      link: '/search?query=Solo+Experiences',
      image: '/images/home-page/categories/solo.png',
      color: '#60A5FA',
      highlight: '#EFF6FF',
   },
   {
      value: 'ladies',
      icon: <LadiesIcon className=" !transform-none !scale-100 relative z-10" />,
      text: <Translate id="home:ladies" />,
      link: '/search?query=Experiences+for+Ladies',
      image: '/images/home-page/categories/ladies.png',
      color: '#F472B6',
      highlight: '#FDF2F8',
   },
];

interface BreakPoints {
   [ratio: string]: SwiperOptions;
   [width: number]: SwiperOptions;
}

const breakpoints: BreakPoints = {
   1280: { slidesPerView: 5, slidesPerGroup: 5 },
   1024: { slidesPerView: 3.2, slidesPerGroup: 3.2 },
   785: { slidesPerView: 2.4, slidesPerGroup: 2.4 },
   767: { slidesPerView: 2.3, slidesPerGroup: 2 },
   360: { slidesPerView: 2.3, slidesPerGroup: 2 },
};

const Categories = () => {
   const { dir } = useTranslate();

   return (
      <section className="mb-14">
         <Fragment>
            <div className="container px-4 mx-auto mb-8">
               <div className="mb-1 text-2xl font-bold">
                  <Translate id="common:discover unforgettable experiences" />
               </div>
               <div className="subheading ">
                  <Translate id="common:we have something for everyone" />
               </div>
            </div>

            <div className="md:container md:px-4 md:mx-auto">
               <Swiper
                  className="!px-4 md:!px-0"
                  breakpoints={breakpoints}
                  key={dir + 'categories_slider'}
                  dir={dir}
                  spaceBetween={10}>
                  {categoriesList.map(
                     ({ value, icon, text, link, image, color, highlight }, index) => (
                        <SwiperSlide key={index} className={style['category-card']}>
                           <NextLink
                              href={link}
                              className="flex w-full"
                              onClick={() => homepageUnforgettableExperiencesBtnEvent(value)}>
                              <div
                                 className="w-80 h-48 md:h-32 flex rounded-[14px] border-4 relative overflow-hidden"
                                 style={{ borderColor: `${color}` }}>
                                 <div className="flex items-center justify-center absolute -top-1 -left-2.5">
                                    <CategoryIconHolder highlight={highlight} />
                                    <span className="absolute inset-0 flex items-center justify-center mx-auto w-6">
                                       {icon}
                                    </span>
                                 </div>

                                 <div className="flex w-full pt-4">
                                    <div
                                       className="text-xl font-circular font-bold mt-10 px-2 tracking-tight relative z-10 rtl:mr-auto "
                                       style={{
                                          color: `${color}`,
                                       }}>
                                       {text}
                                    </div>
                                    <div className="flex items-center justify-center absolute bottom-0 -right-5 md:right-0">
                                       <div className="relative">
                                          <CategoryImageHolder
                                             highlight={highlight}
                                             width={'143px'}
                                             height={'94px'}
                                          />
                                       </div>
                                       <div className="absolute -bottom-6 -right-6 p-4">
                                          <Image src={image} width="143px" height="94px" />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </NextLink>
                        </SwiperSlide>
                     )
                  )}
               </Swiper>
            </div>
         </Fragment>
      </section>
   );
};

const CategoryImageHolder = ({ highlight, width, height }: any) => {
   return (
      <svg
         width={width}
         height={height}
         viewBox="0 0 189 133"
         fill="none"
         className="svg-element"
         xmlns="http://www.w3.org/2000/svg">
         <path
            d="M201.555 72.28C218.78 134.83 77.0242 139 40.011 139C2.99775 139 0 94.0595 0 59.77C0 25.4805 8.06097 2.16517e-05 67.0478 0C126.035 -2.16516e-05 184.331 9.73 201.555 72.28Z"
            fill={`${highlight}`}
         />
      </svg>
   );
};

const CategoryIconHolder = ({ highlight }: any) => {
   return (
      <svg
         width="60"
         height="50"
         viewBox="0 0 60 50"
         fill="none"
         className="svg-element"
         xmlns="http://www.w3.org/2000/svg">
         <path
            d="M-3.36978 -38.2813C16.0635 -52.193 39.6225 -38.5445 52.4968 -15.9682C65.3711 6.60821 61.2321 33.2218 43.2521 43.475C25.2721 53.7282 -0.425292 53.8287 -12.6145 21.1619C-24.8038 -11.505 -22.803 -24.3695 -3.36978 -38.2813Z"
            fill={`${highlight}`}
         />
      </svg>
   );
};

export default Categories;
