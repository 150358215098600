import { Fragment } from 'react';
import { NextLink, Translate, useTranslate } from '~/i18n';
import { AngelLeftIcon, AngelRightIcon } from '~/svgs/common';
import CardsSwiper, { CardsSwiperLoading } from '../cards-swiper';
import cn from 'classnames';
import { homepageKAFUExpBtnEvent } from '~/utils/mixpanel-events/homepage';
interface SliderSectionProps {
   isHeadingSize?: boolean;
   title?: React.ReactNode;
   subtitle?: React.ReactNode;
   count?: number;
   loading?: boolean;
   id: string;
   content?: any[];
   href?: string;
   color?: string;
   is_kafu?: boolean;
}

const SliderSection = ({
   loading,
   content,
   title,
   subtitle,
   count,
   id,
   href,
   isHeadingSize,
   color,
   is_kafu,
}: SliderSectionProps) => {
   const { dir } = useTranslate();
   const Tag = is_kafu ? 'a' : NextLink;

   return (
      <Fragment>
         <div className="container px-4 mx-auto">
            {title && href ? (
               <Tag href={href} className={cn('hover:underline', color && `text-${color}`)}>
                  <span className={cn('mb-1 font-bold', isHeadingSize ? 'heading' : ' text-2xl ')}>
                     {title}
                  </span>
               </Tag>
            ) : title ? (
               <span className={cn('mb-1 font-bold', isHeadingSize ? 'heading' : ' text-2xl ')}>
                  {title}
               </span>
            ) : null}
            <div className="flex flex-wrap items-center mb-8 space-y-2">
               {subtitle && (
                  <span
                     className={cn(
                        'subheading',
                        color && `text-${color}`,
                        subtitle && !title && 'text-2xl font-bold'
                     )}>
                     {subtitle}
                  </span>
               )}
               <div className="flex items-center">
                  {/* {loading && <CardCountsLoader uniqueKey={id + '-title'} />} */}
                  {!loading && href && (
                     <Tag
                        href={href}
                        className={cn(
                           'font-bold  md:ltr:mr-4 md:rtl:ml-4 hover:underline underline',
                           color ? `text-${color}` : 'text-primary-color'
                        )}
                        onClick={() => is_kafu && homepageKAFUExpBtnEvent()}>
                        <Translate id="common:explore all" values={{ count: count + '' }} />
                     </Tag>
                  )}
                  <button
                     disabled={loading}
                     className={cn(
                        `swiper-arrow ltr:mr-2 rtl:ml-2 ${id}-prev focus:outline-none focus:ring-1`,
                        color ? `ring-${color} ${color}` : 'ring-primary-light primary'
                     )}
                     aria-label="previous">
                     {dir === 'rtl' ? <AngelRightIcon /> : <AngelLeftIcon />}
                  </button>
                  <button
                     disabled={loading}
                     className={cn(
                        `swiper-arrow  ${id}-next focus:outline-none focus:ring-1 `,
                        color ? `ring-${color} ${color}` : 'ring-primary-light primary'
                     )}
                     aria-label="next">
                     {dir === 'rtl' ? <AngelLeftIcon /> : <AngelRightIcon />}
                  </button>
               </div>
            </div>
         </div>

         <div className="md:container md:px-4 md:mx-auto">
            {loading && <CardsSwiperLoading uniqueKey={id + 'loading'} />}

            {!loading && content && (
               <CardsSwiper
                  id={`${id + 'swiper'}`}
                  content={content}
                  prevEl={`.${id}-prev`}
                  nextEl={`.${id}-next`}
               />
            )}
         </div>
      </Fragment>
   );
};

export default SliderSection;
