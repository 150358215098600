import { Fragment, useEffect } from 'react';
import { NextSeo } from 'next-seo';
import HomeTopSection from '~/components/home-page/top-section';
import FeaturedExperiences from '~/components/home-page/featured-section';
import { Translate, useTranslate } from '~/i18n';
import TopExperiences from '~/components/home-page/top-experiences';
import Navigators from '~/components/home-page/navigators';
import Vouchers from '~/components/home-page/vouchers';
import { isJarir } from '~/utils/theme';
// import JarirHomeTopSection from '~/custom/jarir/home-page/top-section';
import { Region } from '~/components/home-page/cities-slider';
import KafuSection from '~/components/kafu';
import Head from 'next/head';
import { CategoryList, CategoryResponse } from '~/interfaces/category';
// import STASection from '~/components/home-page/sta-section';
import TopPlaces from '~/components/home-page/top-places-section';
import { useInView } from 'react-intersection-observer';
import { homePageGetStaticPaths, homePageGetStaticProps } from '~/utils/static-props/home-page';
import HyproBanner from '~/components/home-page/hypro-banner';
import Container from '~/components/common/container';
import {
   homepagePageViewEvent,
   homepageSellYourExpMidPageBtnEvent,
} from '~/utils/mixpanel-events/homepage';
import Categories from '~/components/home-page/categories';

interface HomePageProps {
   tickets: any[];
   featured?: CategoryResponse;
   latest?: CategoryResponse;
   deals?: CategoryResponse;
   summerSplash?: any[];
   summerBreeze?: any[];
   regions: Region[];
   categories: CategoryList;
   kafuCards: any[];
   carouselEvents: any[];
   topEvents: CategoryResponse;
}

const HomePage = ({
   featured,
   latest,
   deals,
   categories,
   regions,
   kafuCards,
   // summerBreeze,
   // summerSplash,
   carouselEvents,
   topEvents,
}: HomePageProps) => {
   const { translate } = useTranslate();
   const { ref, inView } = useInView({
      threshold: 0.1,
      rootMargin: '500px',
      triggerOnce: true,
   });

   useEffect(() => {
      homepagePageViewEvent();
   }, []);

   return (
      <Fragment>
         <NextSeo
            title={translate({ id: 'seo:home title' })}
            description={translate({ id: 'seo:home description' })}
            additionalMetaTags={[
               { name: 'keywords', content: translate({ id: 'seo:home keywords' }) },
            ]}
         />
         <Head>
            {!isJarir && (
               <meta
                  name="apple-itunes-app"
                  content={`app-id=1097399425, app-argument=https://app.halayalla.com/`}
               />
            )}
         </Head>
         <div className="hidden">
            <h1>
               <Translate id="seo:home h1" />
            </h1>
            {translate({ id: 'seo:home h2' })?.map((text: string, idx: number) => (
               <h2 key={idx}>{text}</h2>
            ))}
            {translate({ id: 'seo:home h3' })?.map((text: string, idx: number) => (
               <h3 key={idx}>{text}</h3>
            ))}
         </div>

         <HomeTopSection carouselEvents={carouselEvents} />

         <TopPlaces regions={regions} />
         <div className="pt-10 mb-10 lg:pt-2">
            <Navigators categories={categories} />
         </div>

         {/* {!isJarir ? <Newsletter /> : null} */}

         <Categories />

         {featured?.data?.length ? (
            <FeaturedExperiences
               data={featured.data}
               id="latest-experiences"
               title={<Translate id="common:most popular experiences" />}
               subtitle={<Translate id="common:most popular experiences subtitle" />}
               href="/all-cities/experiences?sort=popularity"
            />
         ) : null}

         <Container>
            <HyproBanner trackEvent={homepageSellYourExpMidPageBtnEvent} />
         </Container>

         {topEvents?.data?.length ? (
            <FeaturedExperiences
               data={topEvents.data}
               id="latest-events"
               title={<Translate id="common:top selling events" />}
               subtitle={<Translate id="common:top selling events subtitle" />}
               href="/all-cities/experiences?type=events"
            />
         ) : null}

         {latest?.data?.length ? (
            <FeaturedExperiences
               data={latest.data}
               id="deals-experiences"
               title={<Translate id="common:newly added" />}
               subtitle={<Translate id="common:newly added subtitle" />}
               href="/all-cities/experiences?sort=latest"
            />
         ) : null}

         {deals?.data?.length ? (
            <div
               className="bg-[#F8F9FA] pt-14 pb-5 bg-no-repeat"
               style={{ backgroundImage: 'url("/images/home-page/dbg.svg")' }}>
               <FeaturedExperiences
                  data={deals.data}
                  id="featured-experiences"
                  title={<Translate id="common:best deals" />}
                  subtitle={<Translate id="common:best deals subtitle" />}
                  href="/all-cities/experiences?offers=true"
                  // href="/experiences/all-cities"
               />
            </div>
         ) : null}
         {/* <STASection summerBreeze={summerBreeze} summerSplash={summerSplash} /> */}
         {!isJarir && <div className="py-10"></div>}
         {inView ? (
            <Fragment>
               {/* <TopPlaces regions={regions} /> */}
               {!isJarir && <Vouchers />}
               <TopExperiences categories={categories} />
               {!isJarir && <KafuSection content={kafuCards} />}
            </Fragment>
         ) : (
            <div className="py-10" ref={ref}></div>
         )}
      </Fragment>
   );
};

export const getStaticPaths = homePageGetStaticPaths;

export const getStaticProps = homePageGetStaticProps;

HomePage.transparentNavbar = true;
export default HomePage;
