import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import { useTranslate } from '~/i18n';
import styles from './card.module.css';
export const CardCountsLoader = (props: IContentLoaderProps) => {
   const { dir } = useTranslate();
   return (
      <ContentLoader {...props} width="120px" height="25px" speed={1} rtl={dir === 'rtl'}>
         <rect x="0" y="2" rx="5" ry="5" width="90" height="20" />
      </ContentLoader>
   );
};

interface CardLoaderProps extends IContentLoaderProps {
   uniqueKey: string;
   small?: boolean;
}

const CardLoader = ({ uniqueKey, small, ...props }: CardLoaderProps) => {
   const { dir } = useTranslate();

   if (small) {
      return (
         <div className="bg-white rounded-default mt-1">
            <ContentLoader
               width="100%"
               height="287px"
               uniqueKey={uniqueKey}
               speed={1}
               {...props}
               rtl={dir === 'rtl'}>
               <rect x="0" y="0" rx="5" ry="5" width="400" height="180" />
               <rect x="12" y="190" rx="5" ry="5" width="1445" height="20" />
               <rect x="12" y="220" rx="5" ry="5" width="160" height="20" />
               <rect x="12" y="250" rx="5" ry="5" width="150" height="20" />
            </ContentLoader>
         </div>
      );
   }
   return (
      <div className="bg-white rounded-default border border-gray-200 border-opacity-50 mt-1">
         <ContentLoader
            className={styles.card}
            height="346px"
            width="100%"
            uniqueKey={uniqueKey}
            speed={1}
            {...props}
            rtl={dir === 'rtl'}>
            <rect x="0" y="0" rx="5" ry="5" width="400" height="186" />
            <rect x="12" y="200" rx="5" ry="5" width="145" height="20" />
            <rect x="12" y="240" rx="5" ry="5" width="60" height="20" />
            <rect x="12" y="270" rx="5" ry="5" width="110" height="20" />
            <rect x="12" y="300" rx="5" ry="5" width="60" height="20" />
         </ContentLoader>
      </div>
   );
};

export default CardLoader;
