import { NextLink, Translate, useTranslate } from '~/i18n';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper';
import Image from '~/components/common/image';
import { AngelLeftIcon, AngelRightIcon } from '~/svgs/common';
import { isJarir } from '~/utils/theme';
import { ImageWithPlaceholder } from '~/utils/image-plaiceholder';
import { formatNumber } from '~/utils/format-price';

type CarouselProps = {
   events: {
      src: string;
      city: string;
      title: string;
      price: number;
      href: string;
      optimized_image?: ImageWithPlaceholder;
      event_is_group?: boolean;
   }[];
};

const TopCarousel = ({ events = [] }: CarouselProps) => {
   const { dir, locale } = useTranslate();

   interface BreakPoints {
      [ratio: string]: SwiperOptions;
      [width: number]: SwiperOptions;
   }
   const breakpoints: BreakPoints = {
      2850: { slidesPerView: 3.3 },
      2650: { slidesPerView: 3.1 },
      2450: { slidesPerView: 2.9 },
      2250: { slidesPerView: 2.7 },
      2050: { slidesPerView: 2.5 },
      1850: { slidesPerView: 2.2 },
      1650: { slidesPerView: 2.1 },
      1450: { slidesPerView: 1.9 },
      1350: { slidesPerView: 1.8 },
      1138: { slidesPerView: 1.7 },
      1024: { slidesPerView: 1.6 },
      940: { slidesPerView: 2 },
      768: { slidesPerView: 1.5 },
      320: { slidesPerView: 1 },
   };

   const items = events.map(event => {
      return (
         <SwiperSlide key={event.href}>
            <div className="w-full h-[250px] md:h-[365px] lg:h-[455px] z-0 overflow-hidden  md:rounded-xl">
               <Image
                  alt=""
                  src={event.optimized_image?.img?.src || event.src}
                  optimized_image={event.optimized_image}
                  layout="fill"
                  onError={e => {
                     const image = e.target as HTMLImageElement;
                     image.srcset = '';
                     image.src = isJarir ? '/jarir/not_found.svg' : '/images/not_found.svg';
                  }}
                  className="object-cover md:rounded-xl"
                  priority
               />
               <NextLink href={`${event.href.replace(':lang', locale)}`}>
                  <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black md:rounded-xl"></div>
                  <div className="absolute bottom-6 lg:bottom-12 px-4 lg:px-[35px]">
                     <div>
                        <div className=" bg-[#212529] bg-opacity-50 rounded-full w-min whitespace-nowrap  mb-[4px]">
                           <div className="text-white px-3 py-1 lg:py-1.5">{event.city}</div>
                        </div>
                     </div>
                     <div className=" text-white text-[23px] lg:text-[36px] font-bold leading-[41px] mb-[10px]">
                        {event.title}
                     </div>

                     <div className=" text-white lg:text-[20.7px] ">
                        {event.price > 0 ? (
                           <Translate
                              parseHTML
                              id="common:starts from price"
                              values={{ start_price: formatNumber(event.price) + '' }}
                           />
                        ) : (
                           <Translate id="common:free" />
                        )}
                     </div>
                  </div>
               </NextLink>
            </div>
         </SwiperSlide>
      );
   });

   return (
      <div>
         <div className=" w-full mx-auto  max-w-[2800px]">
            <Swiper
               // loop
               pagination={{
                  bulletActiveClass: 'active',
                  bulletClass: 'pagination-top-section',
                  el: '.pagination-wrapper',
                  clickable: true,
                  clickableClass: 'active',
               }}
               centeredSlides
               slideToClickedSlide
               centeredSlidesBounds
               spaceBetween={12}
               loopedSlides={events.length}
               loop
               slidesPerView={3.3}
               breakpoints={breakpoints}
               key={`${dir}-events`}
               dir={dir}
               navigation={{ prevEl: '.events-prev', nextEl: '.events-next' }}
               initialSlide={1}>
               <div className="hidden lg:flex">
                  <button
                     className="absolute z-10 swiper-arrow ltr:mr-2 rtl:ml-2 events-prev focus:outline-none focus:ring-1 bg-gray-50 ring-primary-light primary top-1/2 ltr:left-5 rtl:right-5"
                     aria-label="previous">
                     {dir === 'rtl' ? <AngelRightIcon /> : <AngelLeftIcon />}
                  </button>
                  <button
                     className="absolute z-10 swiper-arrow events-next focus:outline-none focus:ring-1 bg-gray-50 ring-primary-light primary top-1/2 ltr:right-5 rtl:left-5"
                     aria-label="next">
                     {dir === 'rtl' ? <AngelLeftIcon /> : <AngelRightIcon />}
                  </button>
               </div>
               <div>{items}</div>
               <div className="absolute left-0 right-0 z-10 space-x-1 text-center pagination-wrapper rtl:space-x-reverse bottom-1 md:bottom-3 sm:relative sm:mt-5"></div>
            </Swiper>
         </div>
      </div>
   );
};

export default TopCarousel;
