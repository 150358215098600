import { useRouter } from 'next/router';
import { Translate, useTranslate } from '~/i18n';
import classNames from 'classnames';
import Container from '~/components/common/container';
import { HalaYallaSearch } from '~/svgs/home-page';
import { HYArLogo, HYLogo } from '~/svgs/common';
import { isJarir } from '~/utils/theme';
import Carousel from './carousel';

const TopSection = ({ carouselEvents }: { carouselEvents: any[] }) => {
   const router = useRouter();
   const { lang, translate } = useTranslate();
   const submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const element = e.currentTarget.search as HTMLInputElement;
      const query = element.value ? `?query=${element.value}` : '';
      router.push(`/${lang}/search${query}`);
   };

   return (
      <div className="pt-12">
         <Container className="text-center gird justify-items-center md:mt-20 lg:mb-[18px]">
            {isJarir ? (
               <div className="block px-2 small:px-6 lg:px-0 pt-[8px] lg:ltr:mb-2 lg:rtl:mb-5 text-[19px] lg:text-[42px] font-semibold text-center leading-[25px]  tracking-[-0.66px] lg:leading-loose">
                  <span className="text-[#212529]">
                     <Translate id="jarir:home title" />
                  </span>
                  <span className=" text-[#FFA723] uppercase px-2">
                     <Translate id="jarir:jarir tickets" />
                  </span>
               </div>
            ) : (
               <div className="font-semibold text-[24px] md:text-[42px] px-8  small:px-16  lg:px-0 tracking-[-0.83px] lg:tracking-[-1.46px] text-[#212529] text-center leading-10 mb-4 lg:mb-5">
                  <Translate id="home:welcome title" />
               </div>
            )}
            {isJarir ? (
               <div className="flex items-center justify-center w-full text-[13px] lg:text-sm font-medium my-1 lg:my-3">
                  <span className="lg:px-2">
                     <Translate id="jarir:brought by" />
                  </span>
                  {lang === 'sa-ar' ? (
                     <HYArLogo style={{ height: 35, width: 54.81 }} className="p-1.5 lg:p-0" />
                  ) : (
                     <HYLogo style={{ height: 35, width: 54.81 }} className="p-1.5 lg:p-0" />
                  )}
               </div>
            ) : null}
            <div className="pb-[6px] lg:pb-[10px] w-full">
               <div className="flex-grow flex items-center min-w-0  mb-[12px]">
                  <div className="relative flex-grow max-w-full lg:mx-48 ">
                     <form className="flex" noValidate autoComplete="off" onSubmit={submitSearch}>
                        <input
                           placeholder={translate({ id: 'home:search pleaceholder' })}
                           role="search"
                           id="search"
                           name="search"
                           autoComplete="off"
                           aria-labelledby="search-label"
                           className={classNames(
                              ' flex-grow border border-transparent leading-none text-sm max-w-full h-[44px] md:h-[57px] px-6 pt-1  rounded-full bg-[#E9ECEF] w-full',
                              ' shadow-sm focus:shadow-md focus:outline-none focus:ring-1 ring-offset-transparent ring-offset-1 ring-primary-color '
                           )}
                        />
                        <label
                           id="search-label"
                           htmlFor="search"
                           className="absolute inset-y-0 flex items-center ltr:right-0 rtl:left-0 ltr:pr-1 rtl:pl-1"
                           aria-label="search">
                           <button
                              onClick={() => submitSearch}
                              aria-label={translate({ id: 'common:search' })}
                              className="rounded-full bg-black p-[5px] md:p-[10px]">
                              <HalaYallaSearch />
                           </button>
                        </label>
                     </form>
                  </div>
               </div>
            </div>
         </Container>
         <Carousel events={carouselEvents} />
      </div>
   );
};

export default TopSection;
