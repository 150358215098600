import { useRouter } from 'next/router';
import { NextLink, useTranslate, Translate } from '~/i18n';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper';
import classNames from 'classnames';
import { Fragment } from 'react';
import Image from '~/components/common/image';
import { isJarir } from '~/utils/theme';
import cn from 'classnames';
import styles from './styles.module.css';
import { ImageWithPlaceholder } from '~/utils/image-plaiceholder';
import { homepageTopLocatinBtnEvent } from '~/utils/mixpanel-events/homepage';

interface BreakPoints {
   [ratio: string]: SwiperOptions;
   [width: number]: SwiperOptions;
}

const breakpoints: BreakPoints = {
   1280: { slidesPerView: 5, spaceBetween: 20, slidesPerGroup: 4 },
   1024: { slidesPerView: 4, slidesPerGroup: 3.2 },
   785: { slidesPerView: 3.4, slidesPerGroup: 2.4 },
   767: { slidesPerView: 2.3, slidesPerGroup: 2.3 },
   640: { slidesPerView: 2.2, slidesPerGroup: 2.2 },
   615: { slidesPerView: 1.9, slidesPerGroup: 1.9 },
   580: { slidesPerView: 1.8, slidesPerGroup: 1.8 },
   550: { slidesPerView: 1.7, slidesPerGroup: 1.7 },
   530: { slidesPerView: 1.6, slidesPerGroup: 1.6 },
   380: { slidesPerView: 1.1, slidesPerGroup: 1.1 },
   360: { slidesPerView: 1.1, slidesPerGroup: 1.1 },
};

const mobileBreakpoints: BreakPoints = {
   640: { slidesPerView: 4.8 },
   370: { slidesPerView: 4.5 },
};
const homepageBreakpoints: BreakPoints = {
   900: { slidesPerView: 5 },
   860: { slidesPerView: 4.4 },
   840: { slidesPerView: 4.1 },
   640: { slidesPerView: 3.7 },
   615: { slidesPerView: 3.4 },
   580: { slidesPerView: 3.3 },
   490: { slidesPerView: 2.8 },
   460: { slidesPerView: 2.5 },
   420: { slidesPerView: 2.1 },
   360: { slidesPerView: 1.9 },
   210: { slidesPerView: 1.6 },
};
interface CitiesSliderProps {
   homepage?: boolean;
   small?: boolean;
   regions?: Region[];
   white?: boolean;
}

export type Region = {
   id: string;
   name: string;
   slug: string;
   background_image: string;
   total_experiences: number;
   optimized_image?: ImageWithPlaceholder;
};

const CitiesSlider = ({ small, regions: r, homepage }: CitiesSliderProps) => {
   const { dir, translate } = useTranslate();
   const regions = [...(r || [])];
   regions?.splice(0, 1);
   const router = useRouter();
   const isHomeRoute = router.pathname === `/[lang]`;

   return regions?.length ? (
      <div>
         <Swiper
            className={cn('text-center', homepage && '!px-4')}
            slidesPerView={small ? 4 : 1.1}
            key={dir + 'cities_slider'}
            spaceBetween={8}
            breakpoints={
               !small ? (homepage ? homepageBreakpoints : breakpoints) : mobileBreakpoints
            }
            navigation={{ prevEl: '.cities-slider-prev', nextEl: '.cities-slider-next' }}
            dir={dir}>
            {regions.map(
               ({ slug, id, name, background_image, total_experiences, optimized_image }) => (
                  <SwiperSlide key={id}>
                     <NextLink
                        href={`/${slug}`}
                        className="flex flex-col items-center justify-start w-full"
                        onClick={() => {
                           if (isHomeRoute) {
                              homepageTopLocatinBtnEvent(name);
                           }
                        }}>
                        {small ? (
                           <Fragment>
                              <Image
                                 // loaderBorderRadius={small ? 32 : 48}
                                 width={small ? 64 : 96}
                                 height={small ? 64 : 96}
                                 title={name}
                                 alt={translate({ id: 'seo:home alt' })}
                                 src={background_image}
                                 // loaderWrapperClassName="rounded-full w-[64px] h-[64px]"
                                 onError={e => {
                                    // setLoading(false);
                                    const image = e.target as HTMLImageElement;
                                    image.srcset = '';
                                    image.src = isJarir
                                       ? '/jarir/not_found.svg'
                                       : '/images/not_found.svg';
                                    // onError && onError(e);
                                 }}
                                 // loaderWrapperClassName="rounded-full"
                                 className={classNames(
                                    'rounded-full  object-cover',
                                    !small
                                       ? homepage
                                          ? 'w-[165px] h-[100px]'
                                          : 'w-24 h-24 mb-4'
                                       : 'w-16 h-16 mb-4'
                                 )}
                                 quality={40}
                              />
                              <span className="block mt-2 text-sm text-center break-words whitespace-pre-wrap">
                                 {name}
                              </span>
                           </Fragment>
                        ) : homepage ? (
                           <div
                              className="overflow-hidden rounded-default h-[100.04px] w-[165.13px]"
                              style={{ position: 'relative' }}>
                              <Image
                                 alt=""
                                 // layout="fill"
                                 optimized_image={optimized_image}
                                 src={background_image}
                                 width={165.13}
                                 height={120.04}
                                 className="object-cover"
                                 onError={e => {
                                    // setLoading(false);
                                    const image = e.target as HTMLImageElement;
                                    image.srcset = '';
                                    image.src = isJarir
                                       ? '/jarir/not_found.svg'
                                       : '/images/not_found.svg';
                                    // onError && onError(e);
                                 }}
                                 quality={40}
                              />
                              <div className="absolute inset-0 z-10 flex items-end p-2 text-white bg-gray-600 bg-opacity-10 ltr:text-left rtl:text-right">
                                 <div>
                                    <div className="text-[19px] font-semibold ">{name}</div>
                                    <div className="text-[13px]">
                                       {total_experiences ? (
                                          <Translate
                                             id="home:city slide experiences"
                                             values={{ number: total_experiences + '' }}
                                          />
                                       ) : null}
                                    </div>
                                 </div>
                              </div>
                              <div className={styles['cities-overlay']}></div>
                           </div>
                        ) : (
                           <div
                              className="overflow-hidden rounded-default h-[145.04px] w-[238.13px]"
                              style={{ position: 'relative' }}>
                              <Image
                                 alt=""
                                 // layout="fill"
                                 src={background_image}
                                 width={238.13}
                                 height={145.04}
                                 className="object-cover"
                                 onError={e => {
                                    // setLoading(false);
                                    const image = e.target as HTMLImageElement;
                                    image.srcset = '';
                                    image.src = isJarir
                                       ? '/jarir/not_found.svg'
                                       : '/images/not_found.svg';
                                    // onError && onError(e);
                                 }}
                                 quality={40}
                              />

                              <div className="absolute inset-0 z-10 flex items-end p-4 text-white bg-gray-600 bg-opacity-10 ltr:text-left rtl:text-right">
                                 <div>
                                    <div className="text-[25px] font-semibold leading-[34px] whitespace-nowrap pb-[3.6px]">
                                       {name}
                                    </div>
                                    <div className="text-[13px]">
                                       {total_experiences ? (
                                          <Translate
                                             id="home:city slide experiences"
                                             values={{ number: total_experiences + '' }}
                                          />
                                       ) : null}
                                    </div>
                                 </div>
                              </div>
                              <div className={styles['cities-overlay']}></div>
                           </div>
                        )}
                     </NextLink>
                  </SwiperSlide>
               )
            )}
         </Swiper>
      </div>
   ) : null;
};

export default CitiesSlider;
