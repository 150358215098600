import { useRouter } from 'next/router';
import { FC, Fragment } from 'react';
import { NextLink, useTranslate, Translate } from '~/i18n';
import classNames from 'classnames';
import styles from './card-link.module.css';
import { homepageCategoryBtnEvent } from '~/utils/mixpanel-events/homepage';

interface CardLinkProps {
   title: React.ReactNode;
   Icon: FC<any>;
   href: string;
   slug?: any;
   external?: boolean;
   hideOnMobile?: boolean;
   isComingSoon?: boolean;
   isCategoryLink?: boolean;
}
const CardLink = ({
   Icon,
   external,
   title,
   href,
   hideOnMobile,
   isComingSoon,
   slug,
   isCategoryLink = false,
}: CardLinkProps) => {
   const router = useRouter();
   const { lang } = useTranslate();
   const Tag = external ? 'a' : NextLink;
   const isHomeRoute = router.pathname === `/[lang]`;
   const isCurrentPath = `/${lang}` + href === decodeURI(router.asPath);

   return (
      <Fragment>
         <Tag
            href={href}
            target={external ? '_blank' : ''}
            className="hidden tablet:block"
            rel={external ? 'noreferrer noopener' : ''}
            onClick={() => {
               if (isHomeRoute && isCategoryLink) {
                  homepageCategoryBtnEvent(slug);
               }
            }}>
            <div
               className={classNames(styles['card'], 'navigator', {
                  [styles['active']]: isCurrentPath,
               })}>
               <div>
                  {isComingSoon ? (
                     <div className="text-white absolute top-2 right-1 bg-[#8492A6] rounded-l-xl !text-xs ltr:p-1 rtl:py-1 rtl:px-2.5">
                        <Translate id="common:coming soon" />
                     </div>
                  ) : null}
                  <div className="flex justify-center mb-2">
                     {Icon && <Icon height="28px" width="28px" />}
                  </div>
                  <div className="h-full font-bold align-text-top text-sm px-2 leading-4">
                     {' '}
                     {title}
                  </div>
               </div>
            </div>
         </Tag>

         <div
            className={classNames(
               'text-center navigator  hover:bg-gray-100 border rounded-md h-full tablet:hidden',
               isCurrentPath ? 'bg-gray-200' : 'bg-white',
               hideOnMobile ? 'hidden' : 'block',
               styles.shadow
            )}>
            <Tag
               href={href}
               className="flex flex-col items-center justify-center h-full px-4 py-3 "
               target={external ? '_blank' : ''}
               rel={external ? 'noreferrer noopener' : ''}
               onClick={() => {
                  if (isHomeRoute && isCategoryLink) {
                     homepageCategoryBtnEvent(slug);
                  }
               }}>
               <div
                  className={classNames(styles['card-mobile'], {
                     active: isCurrentPath,
                  })}>
                  {isComingSoon ? (
                     <div className="text-white absolute top-2 right-1 bg-[#8492A6] rounded-l-xl !text-xs p-1">
                        <Translate id="common:coming soon" />
                     </div>
                  ) : null}

                  {Icon && <Icon width="32px" height="32px" />}
               </div>
               <div>{title}</div>
            </Tag>
         </div>
      </Fragment>
   );
};

export default CardLink;
