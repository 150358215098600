//---------------------------------------- Home-page Voucher Section ----------------------------------------------
import Container from '../../common/container';
import { New, NewAr } from '~/svgs/vouchers';
import { NextLink, Translate, useTranslate } from '~/i18n';
import Button from '~/components/common/button';
import Image from 'next/image';
import { homepageBuyVoucherBtnEvent } from '~/utils/mixpanel-events/homepage';

const MobileVouchers = () => {
   const { dir } = useTranslate();
   return (
      <section className="px-4 h-96 md:pt-24">
         <Container>
            <div>
               <div
                  className="relative flex flex-col items-center w-full px-4 pt-5 bg-no-repeat bg-cover rounded-default h-80"
                  style={{ backgroundImage: 'url("/images/home-page/vouchers-banner-bg.png")' }}>
                  <div className="absolute top-0 flex justify-end transform -translate-y-1/2 ltr:right-0 rtl:left-0">
                     {dir === 'rtl' ? <NewAr /> : <New />}
                  </div>
                  <div className="text-2xl font-bold text-white">
                     <Translate id="vouchers:gift to the loved onces" />
                  </div>
                  <div className="text-base text-center text-white small:mx-8">
                     <Translate id="vouchers:buy voucher or send it to a friend" />
                  </div>
                  <Image
                     alt=""
                     height={250}
                     width={300}
                     src="/images/home-page/vouchers-home.png"
                  />
                  <NextLink href="/vouchers">
                     <Button
                        type="secondary"
                        onClick={homepageBuyVoucherBtnEvent}
                        className="px-12 py-2 font-semibold transform -translate-y-6 border-none small:px-20">
                        <div className="flex justify-center">
                           <Translate id="vouchers:buy vouchers" />
                        </div>
                     </Button>
                  </NextLink>
               </div>
            </div>
         </Container>
      </section>
   );
};

export default MobileVouchers;
