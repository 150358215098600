import Container from '../common/container';
import SliderSection from '../common/slider-section';
import getKafuSlider from '~/utils/get-kafu-slider';
// import normalizeKafu from '~/utils/normalize-kafu';
import { useTranslate, Translate } from '~/i18n';
import { KafuProps } from '~/interfaces/kafu';
import styles from './styles.module.css';
import { WhiteKafuIcon } from '~/svgs/common';
import { homepageKAFULogoBtnEvent } from '~/utils/mixpanel-events/homepage';

type KafuSectionProps = {
   content: KafuProps[];
};
const KafuSection = ({ content }: KafuSectionProps) => {
   const { lang } = useTranslate();
   const { dir } = useTranslate();

   return (
      <div className="bg-black">
         <div className={styles['bg1']}>
            <div className={styles['bg2']}>
               <Container className="py-10">
                  <SliderSection
                     content={getKafuSlider(lang, content)}
                     count={25}
                     id="kafu"
                     loading={false}
                     color="white"
                     href={`https://kafugames.com/${
                        lang?.split('-')[1]
                     }/tournaments/upcoming?category=featured`}
                     title={
                        <div className="text-[#48D96C]">
                           <Translate id="home:kafu title" />
                        </div>
                     }
                     subtitle={<Translate id="home:kafu subtitle" />}
                     is_kafu={true}
                  />
                  <div className="flex justify-center pt-4">
                     <a
                        target="_blank"
                        rel="noreferrer noopener"
                        aria-label="Kafu Games"
                        href={`https://kafugames.com/${lang?.split('-')[1]}`}
                        className="flex items-center px-1 bg-gray-700 rounded-md w-28"
                        onClick={() => homepageKAFULogoBtnEvent()}>
                        {dir === 'ltr' ? (
                           <>
                              <WhiteKafuIcon className="m-1" />
                              <img
                                 alt=""
                                 width="10px"
                                 height="10px"
                                 src="/icons/experiences/white-external.svg"
                                 className="mx-1"
                              />
                           </>
                        ) : (
                           <>
                              <img
                                 alt=""
                                 width="10px"
                                 height="10px"
                                 src="/icons/experiences/white-external.svg"
                                 className="mx-1"
                              />
                              <WhiteKafuIcon className="m-1" />
                           </>
                        )}
                     </a>
                  </div>
               </Container>
            </div>
         </div>
      </div>
   );
};

export default KafuSection;
