// import { Translate } from '~/i18n';
// import ExperiencesMap from './experiences-map';
import SliderSection from '../common/slider-section';
import { ExperienceProps } from '~/interfaces/experience';
// import experiecnesCategories from '~/data/experiences-categories';
import getExperienceSlider from '~/utils/get-experiences-slider';
import { useTranslate } from '~/i18n';

const ExperiencesSection = ({
   data = [],
   title,
   subtitle,
   href,
   id,
}: {
   data?: ExperienceProps[];
   title?: any;
   subtitle?: any;
   href?: string;
   id: string;
}) => {
   const { lang } = useTranslate();

   return (
      <section className="mb-14">
         <SliderSection
            content={getExperienceSlider(lang, data)}
            id={id}
            href={href}
            title={title}
            subtitle={subtitle}
            // title={<Translate id="common:top experiences" />}
            // subtitle={<Translate id="common:top experiences subtitle" />}
            // count={39}
         />
         {/* <ExperiencesMap /> */}
      </section>
   );
};

export default ExperiencesSection;
