// import useMedia from '~/hooks/useMedia';
import MobileVouchers from './mobile-vouchers';
import DesktopVouchers from './desktop-vouchers';
import { Fragment } from 'react';
const Vouchers = () => {
   // const isMobile = useMedia(768);
   return (
      <Fragment>
         <div className="hidden md:block relative">
            <DesktopVouchers />
         </div>
         <div className="md:hidden relative">
            <MobileVouchers />
         </div>
      </Fragment>
   );
};

export default Vouchers;
