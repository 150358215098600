import {
   Adventure,
   Attractions,
   Cinema,
   Classes,
   Concerts,
   Exhibitions,
   Food,
   Sport,
   Training,
} from '~/svgs/navigators';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Translate, useTranslate } from '~/i18n';
import experiecnesCategories, { CategoriesKeys } from '~/data/experiences-categories';

import CardLink from '../common/card-link';
import { CategoryList } from '~/interfaces/category';
import Container from '../common/container';
import { ExperienceIcon } from '~/svgs/navbar';
import { SwiperOptions } from 'swiper';
import classNames from 'classnames';
import { isJarir } from '~/utils/theme';
import styles from './styles.module.css';

const Navigators = ({ categories = [] }: { categories: CategoryList }) => {
   const { lang, dir } = useTranslate();
   const key = lang === 'sa-en' ? 'en' : 'ar';

   const isFeaturedCategory = (_slug: string) => {
      if (_slug === 'all-experiences') return true;
      const _category = categories.find(item => item.slugs.en === _slug && item?.venues_count > 0);
      if (_category) return true;
      return false;
   };
   const getCardHref = (category: CategoriesKeys) =>
      `/all-cities/experiences/${category ? `c/${experiecnesCategories[category]?.[key]}` : ''}`;

   const cards = [
      {
         title: <Translate id="common:adventures" />,
         Icon: Adventure,
         href: getCardHref('adventures-and-activities'),
         slug: 'adventures-and-activities',
      },
      {
         title: <Translate id="common:tours and attractions" />,
         Icon: Attractions,
         href: getCardHref('tours-and-attractions'),
         slug: 'tours-and-attractions',
      },
      {
         title: <Translate id="common:sports and health" />,
         Icon: Sport,
         href: getCardHref('sports-and-tournaments'),
         slug: 'sports-and-tournaments',
      },
      {
         title: <Translate id="common:health and fitness" />,
         Icon: Training,
         href: getCardHref('health-and-fitness'),
         slug: 'health-and-fitness',
      },
      {
         title: <Translate id="common:concerts and shows" />,
         Icon: Concerts,
         href: getCardHref('concerts-and-shows'),
         slug: 'concerts-and-shows',
      },
      {
         title: <Translate id="common:cinema and theatre" />,
         Icon: Cinema,
         href: getCardHref('cinema-and-theatre'),
         slug: 'cinema-and-theatre',
      },
      {
         title: <Translate id="common:exhibitions" />,
         Icon: Exhibitions,
         href: getCardHref('exhibitions'),
         slug: 'exhibitions',
      },
      {
         title: <Translate id="common:classes and training" />,
         Icon: Classes,
         href: getCardHref('classes-and-training'),
         slug: 'classes-and-training',
      },
      {
         title: <Translate id="common:all experiences" />,
         Icon: ExperienceIcon,
         href: getCardHref(''),
         slug: 'all-experiences',
         isComingSoon: false,
      },
   ];

   if (!isJarir) {
      cards.splice(5, 0, {
         title: <Translate id="common:food and drinks" />,
         Icon: Food,
         href: getCardHref('dining-experiences'),
         slug: 'dining-experiences',
      });
   }
   if (isJarir) {
      cards.splice(7, 0, {
         title: <Translate id="common:cinema and theatre" />,
         Icon: Cinema,
         href: getCardHref('cinema-and-theatre'),
         slug: 'cinema-and-theatre',
         isComingSoon: true,
      });
   }
   interface BreakPoints {
      [ratio: string]: SwiperOptions;
      [width: number]: SwiperOptions;
   }
   const breakpoints: BreakPoints = {
      530: { slidesPerView: 3.5, slidesPerGroup: 1 },
      320: { slidesPerView: 2.5, slidesPerGroup: 1 },
   };
   return (
      <div className="xl:mt-8 tablet:z-10">
         {isJarir ? (
            <div>
               <Container>
                  <div className="mb-3 text-2xl font-bold ">
                     <Translate id="common:categories" />
                  </div>
                  <div className="hidden text-xs lg:grid xl:gap-x-6 gap-x-1 gap-y-4 tablet:text-sm lg:grid-cols-5">
                     {cards.map(
                        (card, index) =>
                           isFeaturedCategory(card.slug) && (
                              <CardLink key={index} isCategoryLink={true} {...card} />
                           )
                     )}
                  </div>
               </Container>
               <div className="lg:hidden">
                  <Swiper
                     className="!px-4"
                     spaceBetween={12}
                     breakpoints={breakpoints}
                     key={`${cards}_${lang}`}
                     dir={dir}>
                     {cards.map(
                        (card, index) =>
                           isFeaturedCategory(card.slug) && (
                              <SwiperSlide key={index} className="py-3">
                                 <div className="w-full">
                                    <CardLink isCategoryLink={true} {...card} />
                                 </div>
                              </SwiperSlide>
                           )
                     )}
                  </Swiper>
               </div>
            </div>
         ) : (
            <div className="container px-4 mx-auto mb-12 ">
               <div className="mb-3 text-2xl font-bold ">
                  <Translate id="common:explore by category" />
               </div>
               <div
                  className={classNames(
                     styles['category-grid'],
                     'grid grid-cols-2 text-xs xl:gap-x-4 gap-x-1 gap-y-4 tablet:text-sm lg:grid-cols-3 '
                  )}>
                  {cards.map(
                     (card, index) =>
                        isFeaturedCategory(card.slug) && <CardLink key={index} {...card} />
                  )}
               </div>
            </div>
         )}
      </div>
   );
};

export default Navigators;
