import Container from '../../common/container';
import styles from './styles.module.css';
// import { DesktopVoucher } from '~/svgs/home-page';
import { NextLink, Translate, useTranslate } from '~/i18n';
import { New, NewAr } from '~/svgs/vouchers';
import Button from '~/components/common/button';
import { homepageBuyVoucherBtnEvent } from '~/utils/mixpanel-events/homepage';

const DesktopVouchers = () => {
   const { dir } = useTranslate();

   return (
      <Container>
         <section
            className="flex-col items-center px-4 mb-8 overflow-hidden bg-no-repeat bg-cover h-72 md:pt-24 rounded-default"
            style={{ backgroundImage: 'url("/images/home-page/vouchers-banner-bg.png")' }}>
            <div className="inline">
               <div className="flex justify-end transform -translate-y-36 xl:translate-x-0 lg:translate-x-0 md:ltr:translate-x-10 md:rtl:-translate-x-10">
                  <img
                     height="374px"
                     width="440px"
                     alt=""
                     className="max-w-full"
                     src="/images/home-page/desktop-vouchers.svg"
                  />
               </div>
            </div>
            <div className={styles['text']}>
               {dir === 'rtl' ? <NewAr /> : <New />}
               <div className="w-1/2 text-center">
                  <div className="font-black text-white lg:text-headings md:text-2xl">
                     <Translate id="vouchers:gift to the loved onces" />
                  </div>
                  <div className="py-3 text-sm font-normal text-white bg-clip-text">
                     <Translate id="vouchers:buy voucher or send it to a friend" />
                  </div>
                  <NextLink href="/vouchers">
                     {dir === 'ltr' ? (
                        <Button
                           onClick={homepageBuyVoucherBtnEvent}
                           type="secondary"
                           className="font-semibold xl:w-80 lg:w-72 md:w-64 ">
                           <Translate id="vouchers:buy vouchers" />
                        </Button>
                     ) : (
                        <Button
                           onClick={homepageBuyVoucherBtnEvent}
                           type="secondary"
                           className="transform xl:w-80 lg:w-72 md:w-64">
                           <Translate id="vouchers:buy vouchers" />
                        </Button>
                     )}
                  </NextLink>
               </div>
            </div>
         </section>
      </Container>
   );
};

export default DesktopVouchers;
