import { useTranslate } from '~/i18n';
import SliderSection from '../common/slider-section';
// import FeedBackSection from '../common/feedback';
import getExperienceSlider from '~/utils/get-experiences-slider';
import { CategoryList } from '~/interfaces/category';
import Newsletter from '../newsletter';
import { isJarir } from '~/utils/theme';

type TopExperiencesProps = {
   categories: CategoryList;
};

const TopExperiences = ({ categories }: TopExperiencesProps) => {
   const { lang } = useTranslate();

   return (
      <section className="mt-24">
         <div>
            {categories.map(cat =>
               cat.data?.length ? (
                  <div key={cat.slug} className="mb-24">
                     <SliderSection
                        content={getExperienceSlider(lang, cat.data, cat.title)}
                        count={39}
                        id={cat.slug}
                        loading={false}
                        href={`/all-cities/experiences/c/${cat.slugs?.en}`}
                        title={cat.title}
                        subtitle={cat.description}
                     />
                     {categories.indexOf(cat) === 2 && !isJarir ? (
                        <div className="mt-24">
                           <Newsletter />
                        </div>
                     ) : null}
                  </div>
               ) : null
            )}
         </div>
      </section>
   );
};

export default TopExperiences;
