import { SwiperSlide } from 'swiper/react';
import Card from '~/components/common/card';
import { KafuProps } from '~/interfaces/kafu';
import { DateTime } from 'luxon';
import { homepageKAFUCardEvent } from '~/utils/mixpanel-events/homepage';

const getKafuSlider = (lang: string, array: KafuProps[] = [], alt?: string) => {
   const locale = lang?.split('-')[1];

   return array?.map(
      ({ _id, slug, name, image, category, registration_date, is_game_for_good }) => {
         return (
            <SwiperSlide
               className="h-auto py-2"
               key={_id}
               onClick={() =>
                  homepageKAFUCardEvent({
                     tournamentId: _id,
                     tournamentName: name,
                     game: category,
                  })
               }>
               <Card
                  category={category}
                  image={image}
                  title={name}
                  alt={alt}
                  href={`https://kafugames.com/${
                     lang?.split('-')[1]
                  }/tournament-details/${slug}/overview`}
                  is_kafu={true}
                  external={true}>
                  <div className="text-sm">
                     <span>
                        {DateTime.fromJSDate(new Date(registration_date))
                           .setLocale(locale)
                           .toFormat('DDD')}
                     </span>
                     {is_game_for_good && <div className="pt-2 font-semibold ">#gameforgood</div>}
                  </div>
               </Card>
            </SwiperSlide>
         );
      }
   );
};

export default getKafuSlider;
