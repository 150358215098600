import { Translate, useTranslate } from '~/i18n';
import { IncomingMail } from '~/svgs/common';
import Container from '../common/container';
import Axios from '~/utils/axios';
import Input from '../common/input';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import Button from '../common/button';
import { useState } from 'react';
import Loading from '../common/loaders/loader';
import toast from 'react-hot-toast';
import { WhatsappGreenIcon } from '~/svgs/socials';
import {
   homepageNewslettersSubscribeBtnEvent,
   homepageNewslettersSubscribeBtnSFailedEvent,
   homepageNewslettersSubscribeBtnSuccessEvent,
} from '~/utils/mixpanel-events/homepage';
import Cookies from 'js-cookie';
// import { useEffect } from 'react';

/******************************************************************
 *
 *  THIS COMPONENT WAS GENERATED BY NEXTCRAZY-CLI
 *
 ******************************************************************/
type SubmitObject = {
   name: string;
   email: string;
   mobile: string;
   has_whatsapp: any;
} & {
   [key: string]: any;
};
const Newsletter = () => {
   const [loading, setLoading] = useState(false);
   const [disabled, setDisabled] = useState(false);
   const { translate } = useTranslate();

   const { register, handleSubmit, getValues, errors } = useForm<SubmitObject>();
   const values = getValues();

   const submitHandler = async (obj: SubmitObject) => {
      if (loading) return;

      if (obj.has_whatsapp) obj.has_whatsapp = 'yes';
      else obj.has_whatsapp = 'no';

      setLoading(true);
      homepageNewslettersSubscribeBtnEvent(obj.name, obj.email, obj.mobile);
      try {
         await Axios.post('hyapp/newsletter', {
            ...obj,
         });
         toast.success(translate({ id: 'common:thanks for subscribing' }));
         setDisabled(true);
         Cookies.set('newsletter', 'true');
         homepageNewslettersSubscribeBtnSuccessEvent(obj.name, obj.email, obj.mobile);
      } catch (error: any) {
         setLoading(false);
         const e = error.response?.data;
         if (e?.status === 'Failed') {
            const messages = e.error as { [key: string]: string[] } | string;
            if (typeof messages === 'string') {
               toast.error(messages);
               homepageNewslettersSubscribeBtnSFailedEvent(
                  messages,
                  obj.name,
                  obj.email,
                  obj.mobile
               );
               return;
            }
            const errors = Object.entries(messages);
            const firstMessage = errors?.[0]?.[1]?.[0];
            if (firstMessage) toast.error(firstMessage);
            else toast.error(translate({ id: 'common:already subscribed' }));
            homepageNewslettersSubscribeBtnSFailedEvent(
               firstMessage ? firstMessage : 'Your email is already subscribed',
               obj.name,
               obj.email,
               obj.mobile
            );
         }
      }
      setLoading(false);
   };
   return (
      <Container>
         <div className="rounded-lg shadow-md bg-white xl:flex xl:gap-[24px] py-[20px] px-3 xl:px-[36px] mb-12">
            <div>
               <div className="flex whitespace-nowrap">
                  <IncomingMail className="tablet:ltr:mr-[16px] tablet:rtl:ml-[16px] mb-3 tablet:mb-5 p-1 tablet:p-0" />
                  <div className="text-xl tablet:text-[28px] font-bold pt-1 ">
                     <Translate id="newsletter:sign up for our newsletter" />
                  </div>
               </div>
               <div className="tablet:pt-[11px] text-sm rtl:tracking-tight">
                  <Translate id="newsletter:sign up today" />
               </div>
            </div>
            <form onSubmit={handleSubmit(submitHandler)}>
               <div className="grid w-full mt-3 justify-items-center tablet:flex lg:mt-12 xl:w-2/3 xl:space-x-2 lg:gap-4 xl:gap-0">
                  <Input
                     type="text"
                     id="name"
                     name="name"
                     placeholder={translate({ id: 'newsletter:name' })}
                     className="text-gray rounded-default bg-white tablet:!w-[201px] tablet:!h-[53px] !w-full md:w-[325px] !h-12"
                     groupClassName="mb-2 tablet:mb-4 w-full tablet:w-auto"
                     error={errors.name?.message}
                     valid={!!values.name}
                     innerRef={register({
                        required: translate({ id: 'auth:required field' }),
                     })}
                  />
                  <Input
                     type="email"
                     id="email"
                     name="email"
                     placeholder={translate({ id: 'newsletter:email address' })}
                     className="text-gray rounded-default bg-white tablet:!w-[201px] tablet:!h-[53px] !w-full md:w-[325px] !h-12 xl:rtl:mr-2"
                     groupClassName="mb-2 tablet:mb-4 w-full tablet:w-auto"
                     innerRef={register({
                        validate: value =>
                           validator.isEmail(value) || translate({ id: 'auth:invalid email' }),
                     })}
                     error={errors.email?.message}
                     valid={!!values.email}
                  />
                  <Input
                     type="text"
                     id="phone"
                     name="mobile"
                     inputMode="numeric"
                     placeholder="+966xxxxxxxxx"
                     className="text-gray rounded-default bg-white tablet:!w-[201px] tablet:!h-[53px] !w-full md:w-[325px] !h-12"
                     groupClassName="mb-2 tablet:mb-4 w-full tablet:w-auto"
                     error={errors.mobile?.message && <Translate id="auth:validnum" />}
                     valid={!!values.mobile}
                     innerRef={register}
                  />
                  <Button
                     btnType="submit"
                     className="md:!w-[123px] !px-8 !text-sm tablet:h-[54px] w-full mb-2 xl:mb-0"
                     disabled={loading || disabled}>
                     {loading ? <Loading color="white" /> : <Translate id="newsletter:subscribe" />}
                  </Button>
               </div>
               <div className="flex justify-center -pt-2">
                  <Input
                     id="has_whatsapp"
                     name="has_whatsapp"
                     type="checkbox"
                     className="items-center"
                     defaultChecked
                     label={<Translate id="newsletter:receive the latest updates" />}
                     innerRef={register}
                  />
                  <WhatsappGreenIcon className="mx-1 mt-1" />
                  <span className="mt-1 text-xs sm:mt-0 sm:text-base">
                     <Translate id="newsletter:whatsapp" />
                  </span>
               </div>
            </form>
         </div>
      </Container>
   );
};

export default Newsletter;
