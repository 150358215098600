import { FreeMode, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import CardLoader from './loaders/card';
import { IContentLoaderProps } from 'react-content-loader';
import { useTranslate } from '~/i18n';

interface CardsSwiperProps {
   id: string;
   content: any[];
   prevEl?: HTMLElement | string;
   nextEl?: HTMLElement | string;
}

interface BreakPoints {
   [ratio: string]: SwiperOptions;
   [width: number]: SwiperOptions;
}
interface SwiperLoadersProps extends IContentLoaderProps {
   uniqueKey: string;
   small?: boolean;
}
const breakpoints: BreakPoints = {
   1280: { slidesPerView: 4, spaceBetween: 20, slidesPerGroup: 4 },
   1024: { slidesPerView: 3.2, slidesPerGroup: 3.2 },
   785: { slidesPerView: 2.4, slidesPerGroup: 2.4 },
   767: { slidesPerView: 2.3, slidesPerGroup: 2.3 },
   640: { slidesPerView: 2.05, slidesPerGroup: 2.05 },
   615: { slidesPerView: 1.9, slidesPerGroup: 1.9 },
   580: { slidesPerView: 1.8, slidesPerGroup: 1.8 },
   550: { slidesPerView: 1.7, slidesPerGroup: 1.7 },
   530: { slidesPerView: 1.6, slidesPerGroup: 1.6 },
   380: { slidesPerView: 1.1, slidesPerGroup: 1.1 },
   360: { slidesPerView: 1.1, slidesPerGroup: 1.1 },
};

export const CardsSwiperLoading = ({ uniqueKey, small, ...props }: SwiperLoadersProps) => {
   const { dir } = useTranslate();
   return (
      <Swiper
         dir={dir}
         slidesPerView={1}
         slidesPerGroup={1}
         noSwiping
         cssMode={true}
         allowTouchMove={false}
         spaceBetween={15}
         breakpoints={breakpoints}>
         {[...new Array(5)].map((_, idx) => (
            <SwiperSlide key={idx} className="px-2">
               <CardLoader small={small} uniqueKey={`${uniqueKey}-${idx}`} {...props} />
            </SwiperSlide>
         ))}
      </Swiper>
   );
};

const CardsSwiper = ({ id, content, nextEl, prevEl }: CardsSwiperProps) => {
   const { dir } = useTranslate();
   return (
      <Swiper
         className="!px-4 md:!px-0"
         navigation={nextEl && prevEl ? { nextEl, prevEl } : false}
         lazy
         cssMode={true}
         spaceBetween={15}
         key={`${id}_${dir}`}
         dir={dir}
         slidesPerView={1.1}
         breakpoints={breakpoints}
         freeMode={true}
         modules={[FreeMode]}>
         {content}
      </Swiper>
   );
};

export default CardsSwiper;
